import { mapShipsData } from "maps/dashboardData";
import {
  DashboardActionTypes,
  GET_COMPANY_INFO,
  GET_SHIPS,
  SET_USER_DATA,
} from "types/actions/Dashboard.actions";
import { AuthUser } from "types/models/AuthUser";
import { Company } from "types/models/Company";
import { Ship } from "types/models/Ship";

const initialState: {
  ships: Ship[] | null;
  company: Company | null;
  user: AuthUser | null;
} = {
  ships: null,
  company: null,
  user: {
    id: 0,
    azureAdB2CId: "",
    displayName: "",
    email: "",
    photoURL: "",
    role: "admin",
  }
};

const AccountReducer = (state = initialState, action: DashboardActionTypes) => {
  switch (action.type) {
    case GET_SHIPS:
      return {
        ...state,
        ships: mapShipsData(action.payload),
      };
    case GET_COMPANY_INFO:
      return {
        ...state,
        company: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
export default AccountReducer;
