import moment from "moment";

export enum RoutePermittedRole {
  Admin = "admin",
  User = "user",
  Demo = "demo",
}

export enum RouteTitle {
  "rcm" = "RCM",
  "ship-dashboard" = "Ship Dashboard",
}

export const initialDate = moment().subtract(15, 'days').format('YYYY-M-D');

export const today = moment().format('YYYY-M-D');