import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';

import { ExcessFuelType } from 'types/models/ExcessFuel';

// Create an entity adapter
const excessFuelConsumptionAdapter = createEntityAdapter({
    selectId: (exfuel: ExcessFuelType) => exfuel.shipId + exfuel.timestamp,
});

const initialState = excessFuelConsumptionAdapter.getInitialState();

const excessFuelConsumptionSlice = createSlice({
    name: 'excessFuelConsumption',
    initialState,
    reducers: {
        setExcessFuelConsumption: (state, action: PayloadAction<ExcessFuelType[]>) => {
            excessFuelConsumptionAdapter.setAll(state, action.payload);
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const excessFuelConsumptionSelectors = excessFuelConsumptionAdapter.getSelectors<RootState>(
    (state) => state.excessFuelConsumption
)
export const { setExcessFuelConsumption } = excessFuelConsumptionSlice.actions;

export default excessFuelConsumptionSlice.reducer;