import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

interface UserHeaderSidebarWrapperProps {
  children: ReactNode;

  [x: string]: any;
}

const UserHeaderSidebarWrapper: React.FC<UserHeaderSidebarWrapperProps> = ({
  children,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        bgcolor: "theme.palette.primary.main",
        width: theme => theme.spacing(40),
        display: "flex",
        flexDirection: "column",
        transition: "all 0.5s ease",
        position: { xs: "relative", },
        top: { xs: 0, lg: 10 },
       
        height: '100%',
        left: 0,
        zIndex: 1101,
        "& .app-sidebar": {
          position: "relative",
          top: "auto",
          left: "auto",
          width: "100%",
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default UserHeaderSidebarWrapper;
