import mock from "../MockConfig";
import fuelConsumption from "@crema/services/db/fuelConsumption";
import excessFuelConsumption from "@crema/services/db/excessFuel";
import ApiResponse from "types/models/Response";
import { AxiosRequestConfig } from "axios";
import { FuelConsumption } from "types/models/FuelConsumption";

import { ExcessFuelType } from "types/models/ExcessFuel";


mock.onGet("/ships/fuelconsumption")
    .reply((request: AxiosRequestConfig) => {
        const { shipId, from, to } = request.params;

        const _fuelConsumption: FuelConsumption[] = fuelConsumption.filter((fc: FuelConsumption) => {
            const readingDate = new Date(fc.timestamp);
            const fromDate = new Date(from);
            const toDate = new Date(to);
            
            return (
                fc.shipId === shipId 
                // &&
                // readingDate >= fromDate &&
                // readingDate <= toDate
            );
        });

        const apiResponse: ApiResponse<FuelConsumption[]> = {
            data: _fuelConsumption,
            succeeded: true,
            errors: null,
            message: 'Data fetched',
        };

        return [200, apiResponse];
    });

    mock.onGet("/ships/excessfuelconsumption")
    .reply((request: AxiosRequestConfig) => {
        const { shipId, from, to } = request.params;

        const _excessfuelConsumption: ExcessFuelType[] = excessFuelConsumption.filter(
            (fc: ExcessFuelType) => fc.shipId === shipId,
        );

        const apiResponse: ApiResponse<ExcessFuelType[]> = {
            data: _excessfuelConsumption,
            succeeded: true,
            errors: null,
            message: 'Data fetched',
        };

        return [200, apiResponse];
    });
