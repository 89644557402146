import React, { createContext, ReactNode, useContext, useEffect, useState, } from "react";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { AuthUser } from "types/models/AuthUser";
import jwtAxios, { setAuthToken } from "../jwt-auth";


interface MSALAuthContextProps {
    user: AuthUser | null | undefined;
    ships: any;
    isAuthenticated: boolean;
    isLoading: boolean;
}

interface MSALAuthActionsProps {
    signInUser: () => void;
    logout: () => void;
}

const MSALAuthContext = createContext<MSALAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    ships: []
});

const MSALAuthActionsContext = createContext<MSALAuthActionsProps>({
    signInUser: () => { },
    logout: () => { },
});

export const useMSALAuth = () => useContext(MSALAuthContext);
export const useMSALAuthActions = () => useContext(MSALAuthActionsContext);

interface MSALAuthProviderProps {
    children: ReactNode;
}
const MSALAuthProvider: React.FC<MSALAuthProviderProps> = ({ children }) => {
    const [firebaseData, setJWTAuthData] = useState<MSALAuthContextProps>({
        user: null,
        isAuthenticated: false,
        isLoading: true,
        ships: []
    });
    const { instance, accounts } = useMsal(); // Remove inProgress

    const { login, error: msalError, result } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    const signincallback = process.env.REACT_APP_WEB_API + 'api/auth/signincallback';

    useEffect(() => {
        const getAccount = async () => {
            const token = result?.accessToken;
            if (!token) {
                setJWTAuthData({
                    user: undefined,
                    isLoading: false,
                    isAuthenticated: false,
                    ships: []
                });
                return;
            }
            const account = accounts[0];
            const azureAdB2CId = account?.localAccountId;
            const role = account?.idTokenClaims["extension_role"];
            const email = account?.idTokenClaims.emails ? account.idTokenClaims.emails[0] : null;
            const displayName = account?.idTokenClaims.name;
            setAuthToken(token);

            try {
                const { data }: { data: any /* AuthUserType */ } = await jwtAxios.post(signincallback, {
                    azureAdB2CId: azureAdB2CId,
                    email: email,
                    displayName: displayName,
                    role: role
                });
                setJWTAuthData({
                    user: data.data.user,
                    ships: data.data.ships,
                    isLoading: false,
                    isAuthenticated: true,
                });
            } catch (e) {
                setJWTAuthData({
                    user: undefined,
                    isLoading: false,
                    isAuthenticated: false,
                    ships: [],
                });
            }
        };

        if (result && accounts.length > 0) {
            getAccount();
        }
    }, [result, accounts]);

    const logout = () => {
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    };

    if (msalError) {
        console.error('MSAL Error:', msalError);
    }

    return (
        <MSALAuthContext.Provider value={{ ...firebaseData }}>
            <MSALAuthActionsContext.Provider value={{ signInUser: login, logout }}>
                {children}
            </MSALAuthActionsContext.Provider>
        </MSALAuthContext.Provider>
    );
};

export default MSALAuthProvider;
