import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import routesConfig, { RouterConfigData } from 'pages/routesConfig';
import Icon from '@mui/material/Icon';

import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material';
import { useSidebarContext } from '@crema/utility/AppContextProvider/SidebarContextProvider';
import { useAuthUser } from '@crema/utility/AuthHooks';
import { checkPermission } from '@crema/utility/RouteHelper';

const BottomNavigationItem = ({ item, value, index }) => {
    const { sidebarTextColor } = useSidebarContext();
    const { user } = useAuthUser();
    const navigate = useNavigate();
    const hasPermission = React.useMemo(
        () => checkPermission(item!.permittedRole, user.role),
        [item, user.role]
    );

    if (!hasPermission) {
        return null;
    }
    return <BottomNavigationAction
        onClick={() => navigate(item.url)}
        label={<span style={{ color: value !== index ? alpha(sidebarTextColor, 0.7) : '#fff' }}>{item.title === 'shipDashboard' ? 'Dashboard' : item.title === 'shipPerformance' ? "Performance" : item.title}</span>}
        icon={
            <Icon
                sx={{
                    fontSize: 20,
                    display: "block",
                    mb: 1,
                    color: value !== index ? alpha(sidebarTextColor, 0.7) : '#fff',
                }}
                color="action"
            >
                {item.icon}
            </Icon>
        }
    />
}

export default function SimpleBottomNavigation() {
    const [value, setValue] = React.useState(0);

    return (
        <Box sx={{ width: '100%', display: { lg: 'none', xs: 'block', }, position: 'fixed', bottom: 0 }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {routesConfig.map((item: RouterConfigData, index) => (

                    <BottomNavigationItem item={item} value={value} key={item.id} index={index} />
                ))}

            </BottomNavigation>
        </Box>
    );
}