import React, { ReactNode } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import MSALAuthProvider from '@crema/services/auth/msal-auth/MSALAuthProvider';

type Props = {
    children: ReactNode;
    instance: IPublicClientApplication;
};

const AppAuthProvider = ({ children, instance }: Props) => {
    return (
        <MsalProvider instance={instance}>
            <MSALAuthProvider>
                {children}
            </MSALAuthProvider>
        </MsalProvider>
    );
};

export default AppAuthProvider;
