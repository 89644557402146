import { combineReducers } from "redux";
import Settings from "./Setting";
import Common from "./Common";
import Dashboard from "./Dashboard";
import AccountReducer from "./Account";
import ShipReducer from "./Ships";
import SubSystemReducer from "./Subsystems";
import SensorsReducer from "./Sensors";
import sensorReadingsReducer from "./SensorReadings";
import fuelConsumptionReducer from "./FuelConsumption";
import excessFuelConsumptionReducer from "./ExcessFuelConsumption";
import engineEfficiencyReducer from "./EngineEfficiency";
import { apiService } from "@crema/services/RTK/api";

const reducers = () =>
  combineReducers({
    settings: Settings,
    common: Common,
    dashboard: Dashboard,
    account: AccountReducer,
    ships: ShipReducer,
    subsystems: SubSystemReducer,
    sensors: SensorsReducer,
    sensorReadings: sensorReadingsReducer,
    fuelConsumption: fuelConsumptionReducer,
    excessFuelConsumption: excessFuelConsumptionReducer,
    engineEfficiency: engineEfficiencyReducer,
    [apiService.reducerPath]: apiService.reducer,
  });

export default reducers;
