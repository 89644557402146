import { Company } from "types/models/Company";
import { Ship } from "types/models/Ship";

export const GET_SHIPS = "GET_SHIPS";
export const GET_SHIP_GENERAL_DATA = "GET_SHIP_GENERAL_DATA";
export const GET_SHIP_SYSTEM_DATA = "GET_SHIP_SYSTEM_DATA";
export const GET_COMPANY_INFO = "GET_COMPANY_INFO";
export const GET_SHIP_PERFORMANCE_DATA = "GET_SHIP_PERFORMANCE_DATA";
export const GET_SHIP_SUB_SYSTEM_DATA = "GET_SHIP_SUB_SYSTEM_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export interface GetShipGeneralDataAction {
  type: typeof GET_SHIP_GENERAL_DATA;
  payload: Ship;
}

export interface GetShipPerformanceDataAction {
  type: typeof GET_SHIP_PERFORMANCE_DATA;
  payload: any;
}

export interface GetShipsAction {
  type: typeof GET_SHIPS;
  payload: Ship[];
}

export interface GetShipSystemDataAction {
  type: typeof GET_SHIP_SYSTEM_DATA;
  payload: any;
}

export interface GetCompanyInfoAction {
  type: typeof GET_COMPANY_INFO;
  payload: Company;
}

export interface GetShipSubSystemAction {
  type: typeof GET_SHIP_SUB_SYSTEM_DATA;
  payload: any;
}

export interface SetUserDataAction {
  type: typeof SET_USER_DATA;
  payload: any;
}
export type DashboardActionTypes =
  | GetShipsAction
  | GetShipPerformanceDataAction
  | GetShipGeneralDataAction
  | GetShipSystemDataAction
  | GetCompanyInfoAction
  | GetShipSubSystemAction
  | SetUserDataAction;
