import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { Sensor } from 'types/models/Sensor';

// Create an entity adapter
const sensorsAdapter = createEntityAdapter({
    selectId: (sensor: Sensor) => sensor.id,
});

const initialState = sensorsAdapter.getInitialState({
    loading: false,

});

const sensorsSlice = createSlice({
    name: 'sensors',
    initialState,
    reducers: {
        setSensors: (state, action: PayloadAction<Sensor[]>) => {
            sensorsAdapter.setAll(state, action.payload);
            state.loading = false; // Consider setting loading to false after data is set

        },
        clearSensors: (state) => {
            sensorsAdapter.removeAll(state);
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const sensorsSelectors = sensorsAdapter.getSelectors<RootState>(
    (state) => state.sensors
);

export const { setSensors, clearSensors, setLoading } = sensorsSlice.actions;

export default sensorsSlice.reducer;
