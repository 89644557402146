import React, { ReactNode } from "react";
import { RoutePermittedRole } from "../shared/constants/AppConst";
import { ReactComponent as Overview } from "../assets/icon/overview.svg";
import { ReactComponent as DataInput } from "../assets/icon/data-input.svg";
import { ReactComponent as SRM } from "../assets/icon/SRM.svg";
import { ReactComponent as Compliance } from "../assets/icon/compliance.svg";
import { ReactComponent as Vessel } from "../assets/icon/voyage-optimazation.svg";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole[];
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}
const routesConfig: RouterConfigData[] = [
  {
    id: "overview",
    title: "Overview",
    messageId: "sidebar.sample.overview",
    type: "item",
    permittedRole: [RoutePermittedRole.Admin],
    icon: <Overview />,
    url: "/seaquest/overview",
  },
  {
    id: "srm",
    title: "srm",
    messageId: "sidebar.sample.srm",
    type: "item",
    icon: <SRM />,
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Demo, RoutePermittedRole.Admin],
    url: "/seaquest/rcm",
  },
  {
    id: "data-input",
    title: "dataInput",
    messageId: "sidebar.sample.dataInput",
    type: "item",
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin,],
    icon: <DataInput />,
    url: "/seaquest/data-input",
  },
  {
    id: "Compliance",
    title: "compliance",
    messageId: "sidebar.sample.compliance",
    type: "item",
    permittedRole: [RoutePermittedRole.Admin],
    icon: <Compliance />,
    url: "/seaquest/ship-performance",
  },
  {
    id: "vessel-decarbonization",
    title: "vesselDecarbonization",
    messageId: "sidebar.sample.vesselDecarbonization",
    type: "item",
    icon: <Vessel />,
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    url: "/seaquest/ship-dashboard",
  },
];
export default routesConfig;
