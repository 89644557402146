export const checkPermission = (routeRole: any, userRole: any) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.indexOf(routeRole) >= 0;
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeRole.indexOf(userRole) >= 0;
};

export const  countPermissionsByUserRole = (permissions, userRole) => {
  // Filter the permissions where the userRole is included in the permittedRole array
  const accessiblePermissions = permissions.filter(permission =>
    permission.permittedRole.includes(userRole)
  );
  
  // Return the count of permissions accessible by the user role
  return accessiblePermissions.length;
}
