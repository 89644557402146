// ForJWT Auth
import { getUserFromJwtAuth } from "./helper/AuthHelper";
import { useMSALAuth, useMSALAuthActions } from "@crema/services/auth/msal-auth/MSALAuthProvider";

export const useAuthUser = () => {
  const { user, isAuthenticated, ships } = useMSALAuth();

  return {
    isAuthenticated,
    user: getUserFromJwtAuth(user),
    ships,
  };
};

export const useAuthMethod = () => {
  const { signInUser, logout } = useMSALAuthActions();

  return {
    signInUser,
    logout,
  };
};
