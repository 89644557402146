import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { Ship } from 'types/models/Ship';

// Create an entity adapter
const shipsAdapter = createEntityAdapter({
    selectId: (ship: Ship) => ship.id,
});

// Assuming the initial state for selectedShip should be of type Ship | null
const initialState = shipsAdapter.getInitialState({
    selectedShip: null as Ship | null,
});

const shipsSlice = createSlice({
    name: 'ships',
    initialState,
    reducers: {
        setShips: (state, action: PayloadAction<Ship[]>) => {
            shipsAdapter.setAll(state, action.payload);
            // Set the first ship as the selectedShip
            // Check if the action.payload array is not empty to avoid accessing undefined
            if (action.payload.length > 0) {
                state.selectedShip = action.payload[0];
            }
        },
        // If you need to explicitly set the selectedShip later, you can add another reducer
        setSelectedShip: (state, action: PayloadAction<Ship>) => {
            state.selectedShip = action.payload;
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const shipsSelectors = shipsAdapter.getSelectors<RootState>(
    (state) => state.ships
);

// Exporting actions
export const { setShips, setSelectedShip } = shipsSlice.actions;

export default shipsSlice.reducer;
