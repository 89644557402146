import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { EngineEfficiencyType } from 'types/models/EngineEfficiency';


// Create an entity adapter
const engineEfficiencyAdapter = createEntityAdapter({
    selectId: (fc: EngineEfficiencyType) => fc.shipId + + fc.engineNumber + fc.timestamp,
});

const initialState = engineEfficiencyAdapter.getInitialState();

const engineEfficiencySlice = createSlice({
    name: 'engineEfficiency',
    initialState,
    reducers: {
        setEngineEfficiency: (state, action: PayloadAction<EngineEfficiencyType[]>) => {
            engineEfficiencyAdapter.setAll(state, action.payload);
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const engineEfficiencySelectors = engineEfficiencyAdapter.getSelectors<RootState>(
    (state) => state.engineEfficiency
)
export const { setEngineEfficiency } = engineEfficiencySlice.actions;

export default engineEfficiencySlice.reducer;