import { configureStore } from '@reduxjs/toolkit';
import reducers from "../reducers";
import { apiService } from '@crema/services/RTK/api';

export type AppState = ReturnType<typeof store.getState>;

// Configure and export the store
export const store = configureStore({
  reducer: reducers(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
