import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Ship } from "types/models/Ship";
import { Button, Menu } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
interface ShipSelectProps {
  onShipChange: (id: number) => void;
  selectedShip: Ship;
  ships: Ship[];
}
export default function ShipSelect({
  onShipChange,
  ships,
  selectedShip,
}: ShipSelectProps) {
  
  const handleChange = (event) => {
    setAnchorEl(null);
    onShipChange(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={
          {
            color: 'white!important',
            backgroundColor: '#2B3B55',
            '&:hover': { backgroundColor: '#2B3B55' },
          }
        }
      >
        {selectedShip?.name || 'Select Ship'}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {ships?.map((ship) => (
          <MenuItem
            onClick={handleChange}
            key={ship?.id}
            value={ship?.id}
            sx={{ '&:hover': { backgroundColor: '#2B3B55' } }}
          >
            {ship?.name}
          </MenuItem>
        ))}
      </Menu>

    </>

  );
}
