import { createApi, } from '@reduxjs/toolkit/query/react';
import jwtAxios from '../auth/jwt-auth';
// Adjust the import path accordingly

// Define a custom fetchBaseQuery using jwtAxios
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
    async ({ url, method, data, params }) => {
      try {
        const result = await jwtAxios({ url: baseUrl + url, method, data, params });
        return { data: result.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data,
          },
        };
      }
    };

export const apiService = createApi({
  reducerPath: 'api', // or any other unique name
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_WEB_API, // Ensure this matches your environment variable
  }),
  tagTypes: ['SensorReadings'],
  endpoints: (builder) => ({
    // Define your endpoints here, for example:
    getSensorReadings: builder.query({
      query: (queryArg) => {
        return { url: `subsystems/sensorreadings`, method: 'GET', data: undefined, params: queryArg }
      },
      transformResponse: (response, meta, arg) => response.data.sort((a, b) => a.timeAt.localeCompare(b.timeAt)),
      providesTags: (result, error, id): any => [{ type: 'SensorReadings', id }],
      // keepUnusedDataFor: 0,
    }),


    // Add more endpoints as needed
  }),
});


export const { useGetSensorReadingsQuery } = apiService;