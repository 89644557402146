import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { SubSystem } from 'types/models/Ship';

// Create an entity adapter
const subsystemsAdapter = createEntityAdapter({
    selectId: (subsystem: SubSystem) => subsystem.id,
});

const initialState = subsystemsAdapter.getInitialState({
    loading: false, // Added loading state here
});

const subsystemsSlice = createSlice({
    name: 'subsystems',
    initialState,
    reducers: {
        setSubsystems: (state, action: PayloadAction<SubSystem[]>) => {
            subsystemsAdapter.setAll(state, action.payload);
            state.loading = false; // Consider setting loading to false after data is set
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const subsystemsSelectors = subsystemsAdapter.getSelectors<RootState>(
    (state) => state.subsystems
);

export const { setSubsystems, setLoading } = subsystemsSlice.actions;
export default subsystemsSlice.reducer;
