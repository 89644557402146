import { AuthUser } from "types/models/AuthUser";

export const getUserFromJwtAuth = (user: AuthUser) => {
  if (user)
    return {
      id: 1,
      uid: user.azureAdB2CId,
      displayName: user.displayName,
      email: user.email,
      photoURL: '/assets/images/avatar/A11.jpg',
      role: user.role,
      initialUrl: user.initialUrl
    };
  return user;
};
