import { Ship, ShipFeatureReading } from "types/models/Ship";

// export const mapShipDataByMetric = (
//   data: Ship,
//   metric: string
// ): ShipFeatureReading[] => {
//   return data?.shipFeatures?.find((m) => m.name === metric)
//     ?.shipFeatureReadings
//     .sort(
//       (a, b) => {
//         return new Date(a.timeAt).getTime() - new Date(b.timeAt).getTime();
//       }
//     );
// };
export const mapShipDataByMetric = (
  data: Ship,
  metric: string
): any[] => {
  return []
}

export const mapShipDataToCourseMap = (
  data: Ship
): { lat: number; lon: number; timeAt: string }[] => {
  const lat = mapShipDataByMetric(data, "lat");
  const lon = mapShipDataByMetric(data, "lon");

  return lat?.map((r) => ({
    lat: r?.value,
    lon: lon?.find((item) => item.timeAt === r.timeAt).value,
    timeAt: r?.timeAt,
  }));
};

export const mapShipsData = (data: Ship[]) => {
  return data.map((r) => ({
    ...r,
    lat: mapShipDataByMetric(r, "lat")[0]?.value,
    lng: mapShipDataByMetric(r, "lon")[0]?.value,
    velocity: mapShipDataByMetric(r, "velocity")[0]?.value,
    course: mapShipDataByMetric(r, "course")[0]?.value,
    draft: mapShipDataByMetric(r, "draft")[0]?.value,
  }));
};
