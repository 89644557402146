import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { FuelConsumption } from 'types/models/FuelConsumption';

// Create an entity adapter
const fuelConsumptionAdapter = createEntityAdapter({
    selectId: (fc: FuelConsumption) => fc.shipId + fc.timestamp,
});

const initialState = fuelConsumptionAdapter.getInitialState();

const fuelConsumptionSlice = createSlice({
    name: 'fuelConsumption',
    initialState,
    reducers: {
        setFuelConsumption: (state, action: PayloadAction<FuelConsumption[]>) => {
            fuelConsumptionAdapter.setAll(state, action.payload);
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const fuelConsumptionSelectors = fuelConsumptionAdapter.getSelectors<RootState>(
    (state) => state.fuelConsumption
)
export const { setFuelConsumption } = fuelConsumptionSlice.actions;

export default fuelConsumptionSlice.reducer;