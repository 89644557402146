
import { ExcessFuelType } from "types/models/ExcessFuel";

const ExcessFuel: ExcessFuelType[] = [
    {
        "shipId": 1,
        "timestamp": "2023-01-01 00:00:00+0000",
        "fuelWeather": 31,
        "fuelFouling": 13.16,
        "fuelEngineEfficiency": 8.4,
        "fuelAuxiliaryEngineEfficiency": 3.36,
        "fuelTrim": 3.08
    },
    {
        "shipId": 1,
        "timestamp": "2023-01-08 00:00:00+0000",
        "fuelWeather": 51,
        "fuelFouling": 14,
        "fuelEngineEfficiency": 9.24,
        "fuelAuxiliaryEngineEfficiency": 3.64,
        "fuelTrim": 1.12
    },
    {
        "shipId": 1,
        "timestamp": "2023-01-15 00:00:00+0000",
        "fuelWeather": 46,
        "fuelFouling": 19.38,
        "fuelEngineEfficiency": 11.4,
        "fuelAuxiliaryEngineEfficiency": 4.18,
        "fuelTrim": 3.04
    },
    {
        "shipId": 1,
        "timestamp": "2023-01-22 00:00:00+0000",
        "fuelWeather": 42,
        "fuelFouling": 12.25,
        "fuelEngineEfficiency": 9.5,
        "fuelAuxiliaryEngineEfficiency": 3.25,
        "fuelTrim": 1
    },
    {
        "shipId": 1,
        "timestamp": "2023-01-29 00:00:00+0000",
        "fuelWeather": 55,
        "fuelFouling": 11,
        "fuelEngineEfficiency": 8.36,
        "fuelAuxiliaryEngineEfficiency": 2.2,
        "fuelTrim": 0.44
    },
    {
        "shipId": 1,
        "timestamp": "2023-02-05 00:00:00+0000",
        "fuelWeather": 37,
        "fuelFouling": 12,
        "fuelEngineEfficiency": 8,
        "fuelAuxiliaryEngineEfficiency": 3,
        "fuelTrim": 2
    },
    {
        "shipId": 1,
        "timestamp": "2023-02-12 00:00:00+0000",
        "fuelWeather": 51,
        "fuelFouling": 14.28,
        "fuelEngineEfficiency": 9.4,
        "fuelAuxiliaryEngineEfficiency": 3.08,
        "fuelTrim": 2.24
    },
    {
        "shipId": 1,
        "timestamp": "2023-02-19 00:00:00+0000",
        "fuelWeather": 51,
        "fuelFouling": 19,
        "fuelEngineEfficiency": 12.54,
        "fuelAuxiliaryEngineEfficiency": 4.56,
        "fuelTrim": 1.9
    },
    {
        "shipId": 1,
        "timestamp": "2023-02-26 00:00:00+0000",
        "fuelWeather": 46,
        "fuelFouling": 19.38,
        "fuelEngineEfficiency": 11.4,
        "fuelAuxiliaryEngineEfficiency": 5.32,
        "fuelTrim": 1.9
    },
    {
        "shipId": 1,
        "timestamp": "2023-03-05 00:00:00+0000",
        "fuelWeather": 49,
        "fuelFouling": 12,
        "fuelEngineEfficiency": 8.75,
        "fuelAuxiliaryEngineEfficiency": 3.25,
        "fuelTrim": 1
    },
    {
        "shipId": 1,
        "timestamp": "2023-03-12 00:00:00+0000",
        "fuelWeather": 39,
        "fuelFouling": 14.28,
        "fuelEngineEfficiency": 9.52,
        "fuelAuxiliaryEngineEfficiency": 2.8,
        "fuelTrim": 1.4
    },
    {
        "shipId": 1,
        "timestamp": "2023-03-19 00:00:00+0000",
        "fuelWeather": 38,
        "fuelFouling": 15.5,
        "fuelEngineEfficiency": 9.61,
        "fuelAuxiliaryEngineEfficiency": 2.79,
        "fuelTrim": 3.1
    },
    {
        "shipId": 1,
        "timestamp": "2023-03-26 00:00:00+0000",
        "fuelWeather": 28,
        "fuelFouling": 16.92,
        "fuelEngineEfficiency": 13.32,
        "fuelAuxiliaryEngineEfficiency": 3.96,
        "fuelTrim": 1.8
    },
    {
        "shipId": 1,
        "timestamp": "2023-04-02 00:00:00+0000",
        "fuelWeather": 44,
        "fuelFouling": 8.82,
        "fuelEngineEfficiency": 6.3,
        "fuelAuxiliaryEngineEfficiency": 2.34,
        "fuelTrim": 0.54
    },
    {
        "shipId": 1,
        "timestamp": "2023-04-09 00:00:00+0000",
        "fuelWeather": 21,
        "fuelFouling": 15.64,
        "fuelEngineEfficiency": 12.24,
        "fuelAuxiliaryEngineEfficiency": 3.74,
        "fuelTrim": 2.38
    },
    {
        "shipId": 1,
        "timestamp": "2023-04-16 00:00:00+0000",
        "fuelWeather": 39,
        "fuelFouling": 11.5,
        "fuelEngineEfficiency": 7.36,
        "fuelAuxiliaryEngineEfficiency": 3.45,
        "fuelTrim": 0.69
    },
    {
        "shipId": 1,
        "timestamp": "2023-04-23 00:00:00+0000",
        "fuelWeather": 21,
        "fuelFouling": 6.24,
        "fuelEngineEfficiency": 4.16,
        "fuelAuxiliaryEngineEfficiency": 1.56,
        "fuelTrim": 1.04
    },
    {
        "shipId": 1,
        "timestamp": "2023-04-30 00:00:00+0000",
        "fuelWeather": 45,
        "fuelFouling": 8.33,
        "fuelEngineEfficiency": 5.27,
        "fuelAuxiliaryEngineEfficiency": 2.55,
        "fuelTrim": 0.85
    },
    {
        "shipId": 1,
        "timestamp": "2023-05-07 00:00:00+0000",
        "fuelWeather": 33,
        "fuelFouling": 12.75,
        "fuelEngineEfficiency": 7.5,
        "fuelAuxiliaryEngineEfficiency": 4,
        "fuelTrim": 0.75
    },
    {
        "shipId": 1,
        "timestamp": "2023-05-14 00:00:00+0000",
        "fuelWeather": 41,
        "fuelFouling": 12.75,
        "fuelEngineEfficiency": 7.25,
        "fuelAuxiliaryEngineEfficiency": 4.25,
        "fuelTrim": 0.75
    },
    {
        "shipId": 1,
        "timestamp": "2023-05-21 00:00:00+0000",
        "fuelWeather": 33,
        "fuelFouling": 15.84,
        "fuelEngineEfficiency": 10.56,
        "fuelAuxiliaryEngineEfficiency": 4.62,
        "fuelTrim": 1.98
    },
    {
        "shipId": 1,
        "timestamp": "2023-05-28 00:00:00+0000",
        "fuelWeather": 39,
        "fuelFouling": 11.27,
        "fuelEngineEfficiency": 7.82,
        "fuelAuxiliaryEngineEfficiency": 2.76,
        "fuelTrim": 1.15
    },
    {
        "shipId": 1,
        "timestamp": "2023-06-04 00:00:00+0000",
        "fuelWeather": 44,
        "fuelFouling": 7.5,
        "fuelEngineEfficiency": 5.25,
        "fuelAuxiliaryEngineEfficiency": 1.8,
        "fuelTrim": 0.45
    },
    {
        "shipId": 1,
        "timestamp": "2023-06-11 00:00:00+0000",
        "fuelWeather": 34,
        "fuelFouling": 1.92,
        "fuelEngineEfficiency": 1.28,
        "fuelAuxiliaryEngineEfficiency": 0.48,
        "fuelTrim": 0.32
    },
    {
        "shipId": 2,
        "timestamp": "2023-01-01 00:00:00+00:00",
        "fuelWeather": 38.49,
        "fuelFouling": 11.15,
        "fuelEngineEfficiency": 9.37,
        "fuelAuxiliaryEngineEfficiency": 1.02,
        "fuelTrim": 1.78
    },
    {
        "shipId": 2,
        "timestamp": "2023-01-08 00:00:00+00:00",
        "fuelWeather": 54.62,
        "fuelFouling": 13.78,
        "fuelEngineEfficiency": 6.48,
        "fuelAuxiliaryEngineEfficiency": 4.02,
        "fuelTrim": 1.54
    },
    {
        "shipId": 2,
        "timestamp": "2023-01-15 00:00:00+00:00",
        "fuelWeather": 48.5,
        "fuelFouling": 9.1,
        "fuelEngineEfficiency": 12.76,
        "fuelAuxiliaryEngineEfficiency": 3.62,
        "fuelTrim": 0.51
    },
    {
        "shipId": 2,
        "timestamp": "2023-01-22 00:00:00+00:00",
        "fuelWeather": 44.76,
        "fuelFouling": 11.61,
        "fuelEngineEfficiency": 11.2,
        "fuelAuxiliaryEngineEfficiency": 3.7,
        "fuelTrim": 0.73
    },
    {
        "shipId": 2,
        "timestamp": "2023-01-29 00:00:00+00:00",
        "fuelWeather": 32.37,
        "fuelFouling": 12.24,
        "fuelEngineEfficiency": 12.52,
        "fuelAuxiliaryEngineEfficiency": 3.85,
        "fuelTrim": 0.53
    },
    {
        "shipId": 2,
        "timestamp": "2023-02-05 00:00:00+00:00",
        "fuelWeather": 32.37,
        "fuelFouling": 7.87,
        "fuelEngineEfficiency": 12.16,
        "fuelAuxiliaryEngineEfficiency": 1.27,
        "fuelTrim": 2.07
    },
    {
        "shipId": 2,
        "timestamp": "2023-02-12 00:00:00+00:00",
        "fuelWeather": 29.63,
        "fuelFouling": 12.36,
        "fuelEngineEfficiency": 9.78,
        "fuelAuxiliaryEngineEfficiency": 2.33,
        "fuelTrim": 1.25
    },
    {
        "shipId": 2,
        "timestamp": "2023-02-19 00:00:00+00:00",
        "fuelWeather": 52.25,
        "fuelFouling": 8.86,
        "fuelEngineEfficiency": 12.37,
        "fuelAuxiliaryEngineEfficiency": 1.43,
        "fuelTrim": 1.75
    },
    {
        "shipId": 2,
        "timestamp": "2023-02-26 00:00:00+00:00",
        "fuelWeather": 44.83,
        "fuelFouling": 8.02,
        "fuelEngineEfficiency": 5.71,
        "fuelAuxiliaryEngineEfficiency": 4.19,
        "fuelTrim": 2.76
    },
    {
        "shipId": 2,
        "timestamp": "2023-03-05 00:00:00+00:00",
        "fuelWeather": 47.83,
        "fuelFouling": 15.09,
        "fuelEngineEfficiency": 6.57,
        "fuelAuxiliaryEngineEfficiency": 3.31,
        "fuelTrim": 1.09
    },
    {
        "shipId": 2,
        "timestamp": "2023-03-12 00:00:00+00:00",
        "fuelWeather": 28.58,
        "fuelFouling": 15.23,
        "fuelEngineEfficiency": 5.36,
        "fuelAuxiliaryEngineEfficiency": 2.22,
        "fuelTrim": 1.5
    },
    {
        "shipId": 2,
        "timestamp": "2023-03-19 00:00:00+00:00",
        "fuelWeather": 55.16,
        "fuelFouling": 13.97,
        "fuelEngineEfficiency": 7.6,
        "fuelAuxiliaryEngineEfficiency": 1.24,
        "fuelTrim": 2.38
    },
    {
        "shipId": 2,
        "timestamp": "2023-03-26 00:00:00+00:00",
        "fuelWeather": 51.31,
        "fuelFouling": 9.94,
        "fuelEngineEfficiency": 8.11,
        "fuelAuxiliaryEngineEfficiency": 2.15,
        "fuelTrim": 1.03
    },
    {
        "shipId": 2,
        "timestamp": "2023-04-02 00:00:00+00:00",
        "fuelWeather": 33.95,
        "fuelFouling": 8.28,
        "fuelEngineEfficiency": 7.17,
        "fuelAuxiliaryEngineEfficiency": 2.2,
        "fuelTrim": 0.65
    },
    {
        "shipId": 2,
        "timestamp": "2023-04-09 00:00:00+00:00",
        "fuelWeather": 33.09,
        "fuelFouling": 12.97,
        "fuelEngineEfficiency": 11.63,
        "fuelAuxiliaryEngineEfficiency": 3.7,
        "fuelTrim": 1.19
    },
    {
        "shipId": 2,
        "timestamp": "2023-04-16 00:00:00+00:00",
        "fuelWeather": 33.14,
        "fuelFouling": 11.02,
        "fuelEngineEfficiency": 7.85,
        "fuelAuxiliaryEngineEfficiency": 3.36,
        "fuelTrim": 0.86
    },
    {
        "shipId": 2,
        "timestamp": "2023-04-23 00:00:00+00:00",
        "fuelWeather": 36.52,
        "fuelFouling": 8.48,
        "fuelEngineEfficiency": 7.25,
        "fuelAuxiliaryEngineEfficiency": 4.28,
        "fuelTrim": 2.82
    },
    {
        "shipId": 2,
        "timestamp": "2023-04-30 00:00:00+00:00",
        "fuelWeather": 42.69,
        "fuelFouling": 11.46,
        "fuelEngineEfficiency": 9.34,
        "fuelAuxiliaryEngineEfficiency": 2.75,
        "fuelTrim": 2.51
    },
    {
        "shipId": 2,
        "timestamp": "2023-05-07 00:00:00+00:00",
        "fuelWeather": 40.09,
        "fuelFouling": 7.78,
        "fuelEngineEfficiency": 6.13,
        "fuelAuxiliaryEngineEfficiency": 1.44,
        "fuelTrim": 2.07
    },
    {
        "shipId": 2,
        "timestamp": "2023-05-14 00:00:00+00:00",
        "fuelWeather": 36.15,
        "fuelFouling": 14.77,
        "fuelEngineEfficiency": 11.42,
        "fuelAuxiliaryEngineEfficiency": 3.64,
        "fuelTrim": 2.67
    },
    {
        "shipId": 2,
        "timestamp": "2023-05-21 00:00:00+00:00",
        "fuelWeather": 45.13,
        "fuelFouling": 9.57,
        "fuelEngineEfficiency": 5.6,
        "fuelAuxiliaryEngineEfficiency": 3.81,
        "fuelTrim": 2.5
    },
    {
        "shipId": 2,
        "timestamp": "2023-05-28 00:00:00+00:00",
        "fuelWeather": 31.91,
        "fuelFouling": 12.8,
        "fuelEngineEfficiency": 12.9,
        "fuelAuxiliaryEngineEfficiency": 3.08,
        "fuelTrim": 0.93
    },
    {
        "shipId": 2,
        "timestamp": "2023-06-04 00:00:00+00:00",
        "fuelWeather": 36.18,
        "fuelFouling": 9.99,
        "fuelEngineEfficiency": 11.18,
        "fuelAuxiliaryEngineEfficiency": 3.85,
        "fuelTrim": 2.73
    },
    {
        "shipId": 2,
        "timestamp": "2023-06-11 00:00:00+00:00",
        "fuelWeather": 38.26,
        "fuelFouling": 11.66,
        "fuelEngineEfficiency": 6.59,
        "fuelAuxiliaryEngineEfficiency": 2.83,
        "fuelTrim": 1.83
    },
    {
        "shipId": 3,
        "timestamp": "2024-01-01 00:00:00+00:00",
        "fuelWeather": 52.43,
        "fuelFouling": 14.87,
        "fuelEngineEfficiency": 12.04,
        "fuelAuxiliaryEngineEfficiency": 2.48,
        "fuelTrim": 2.07
    },
    {
        "shipId": 3,
        "timestamp": "2024-01-08 00:00:00+00:00",
        "fuelWeather": 45.63,
        "fuelFouling": 10.56,
        "fuelEngineEfficiency": 9.04,
        "fuelAuxiliaryEngineEfficiency": 2.77,
        "fuelTrim": 1.67
    },
    {
        "shipId": 3,
        "timestamp": "2024-01-15 00:00:00+00:00",
        "fuelWeather": 36.44,
        "fuelFouling": 11.7,
        "fuelEngineEfficiency": 10.57,
        "fuelAuxiliaryEngineEfficiency": 1.92,
        "fuelTrim": 1.93
    },
    {
        "shipId": 3,
        "timestamp": "2024-01-22 00:00:00+00:00",
        "fuelWeather": 53.4,
        "fuelFouling": 10.19,
        "fuelEngineEfficiency": 12.4,
        "fuelAuxiliaryEngineEfficiency": 1.56,
        "fuelTrim": 1.39
    },
    {
        "shipId": 3,
        "timestamp": "2024-01-29 00:00:00+00:00",
        "fuelWeather": 49.98,
        "fuelFouling": 17.07,
        "fuelEngineEfficiency": 18.01,
        "fuelAuxiliaryEngineEfficiency": 2.23,
        "fuelTrim": 2.2
    },
    {
        "shipId": 3,
        "timestamp": "2024-02-05 00:00:00+00:00",
        "fuelWeather": 48.33,
        "fuelFouling": 11.77,
        "fuelEngineEfficiency": 13.56,
        "fuelAuxiliaryEngineEfficiency": 2.05,
        "fuelTrim": 1.87
    },
    {
        "shipId": 3,
        "timestamp": "2024-02-12 00:00:00+00:00",
        "fuelWeather": 31.63,
        "fuelFouling": 13.56,
        "fuelEngineEfficiency": 10.43,
        "fuelAuxiliaryEngineEfficiency": 2.48,
        "fuelTrim": 1.47
    },
    {
        "shipId": 3,
        "timestamp": "2024-02-19 00:00:00+00:00",
        "fuelWeather": 35.81,
        "fuelFouling": 13.51,
        "fuelEngineEfficiency": 14.07,
        "fuelAuxiliaryEngineEfficiency": 1.58,
        "fuelTrim": 1.44
    },
    {
        "shipId": 3,
        "timestamp": "2024-02-26 00:00:00+00:00",
        "fuelWeather": 38.07,
        "fuelFouling": 8.42,
        "fuelEngineEfficiency": 11.4,
        "fuelAuxiliaryEngineEfficiency": 1.06,
        "fuelTrim": 1.24
    },
    {
        "shipId": 3,
        "timestamp": "2024-03-04 00:00:00+00:00",
        "fuelWeather": 52.39,
        "fuelFouling": 18.04,
        "fuelEngineEfficiency": 17.69,
        "fuelAuxiliaryEngineEfficiency": 2.74,
        "fuelTrim": 2.31
    },
    {
        "shipId": 3,
        "timestamp": "2024-03-11 00:00:00+00:00",
        "fuelWeather": 39.35,
        "fuelFouling": 11.69,
        "fuelEngineEfficiency": 12.72,
        "fuelAuxiliaryEngineEfficiency": 2.29,
        "fuelTrim": 1.58
    },
    {
        "shipId": 3,
        "timestamp": "2024-03-18 00:00:00+00:00",
        "fuelWeather": 37.3,
        "fuelFouling": 15,
        "fuelEngineEfficiency": 11.32,
        "fuelAuxiliaryEngineEfficiency": 1.58,
        "fuelTrim": 1.47
    },
    {
        "shipId": 3,
        "timestamp": "2024-03-25 00:00:00+00:00",
        "fuelWeather": 44.49,
        "fuelFouling": 13.43,
        "fuelEngineEfficiency": 11.7,
        "fuelAuxiliaryEngineEfficiency": 1.16,
        "fuelTrim": 1.62
    },
    {
        "shipId": 3,
        "timestamp": "2024-04-01 00:00:00+00:00",
        "fuelWeather": 63.04,
        "fuelFouling": 15.04,
        "fuelEngineEfficiency": 19.91,
        "fuelAuxiliaryEngineEfficiency": 1.84,
        "fuelTrim": 2.51
    },
    {
        "shipId": 3,
        "timestamp": "2024-04-08 00:00:00+00:00",
        "fuelWeather": 54.73,
        "fuelFouling": 14.5,
        "fuelEngineEfficiency": 11.43,
        "fuelAuxiliaryEngineEfficiency": 2.6,
        "fuelTrim": 1.7
    },
    {
        "shipId": 3,
        "timestamp": "2024-04-15 00:00:00+00:00",
        "fuelWeather": 37,
        "fuelFouling": 9.22,
        "fuelEngineEfficiency": 9.78,
        "fuelAuxiliaryEngineEfficiency": 1.71,
        "fuelTrim": 1.18
    },
    {
        "shipId": 3,
        "timestamp": "2024-04-22 00:00:00+00:00",
        "fuelWeather": 38.78,
        "fuelFouling": 9.88,
        "fuelEngineEfficiency": 10.67,
        "fuelAuxiliaryEngineEfficiency": 1.49,
        "fuelTrim": 1.56
    },
    {
        "shipId": 3,
        "timestamp": "2024-04-29 00:00:00+00:00",
        "fuelWeather": 42.96,
        "fuelFouling": 8.44,
        "fuelEngineEfficiency": 12.21,
        "fuelAuxiliaryEngineEfficiency": 1.25,
        "fuelTrim": 1.48
    },
    {
        "shipId": 3,
        "timestamp": "2024-05-06 00:00:00+00:00",
        "fuelWeather": 46.26,
        "fuelFouling": 15.54,
        "fuelEngineEfficiency": 13.34,
        "fuelAuxiliaryEngineEfficiency": 1.88,
        "fuelTrim": 2.16
    },
    {
        "shipId": 3,
        "timestamp": "2024-05-13 00:00:00+00:00",
        "fuelWeather": 30.89,
        "fuelFouling": 11.8,
        "fuelEngineEfficiency": 11.74,
        "fuelAuxiliaryEngineEfficiency": 2.67,
        "fuelTrim": 1.27
    },
    {
        "shipId": 3,
        "timestamp": "2024-05-20 00:00:00+00:00",
        "fuelWeather": 38.26,
        "fuelFouling": 7.74,
        "fuelEngineEfficiency": 9.84,
        "fuelAuxiliaryEngineEfficiency": 1.98,
        "fuelTrim": 1.47
    },
    {
        "shipId": 3,
        "timestamp": "2024-05-27 00:00:00+00:00",
        "fuelWeather": 27.21,
        "fuelFouling": 11.94,
        "fuelEngineEfficiency": 10.24,
        "fuelAuxiliaryEngineEfficiency": 1.15,
        "fuelTrim": 1.31
    },
    {
        "shipId": 3,
        "timestamp": "2024-06-03 00:00:00+00:00",
        "fuelWeather": 41.78,
        "fuelFouling": 16.04,
        "fuelEngineEfficiency": 15.45,
        "fuelAuxiliaryEngineEfficiency": 1.84,
        "fuelTrim": 2.05
    },
    {
        "shipId": 3,
        "timestamp": "2024-06-10 00:00:00+00:00",
        "fuelWeather": 42.7,
        "fuelFouling": 8,
        "fuelEngineEfficiency": 11.43,
        "fuelAuxiliaryEngineEfficiency": 1.91,
        "fuelTrim": 1.1
    },
    {
        "shipId": 3,
        "timestamp": "2024-06-17 00:00:00+00:00",
        "fuelWeather": 47.4,
        "fuelFouling": 12.48,
        "fuelEngineEfficiency": 13.18,
        "fuelAuxiliaryEngineEfficiency": 1.45,
        "fuelTrim": 1.75
    },
    {
        "shipId": 4,
        "timestamp": "2024-01-01 00:00:00+00:00",
        "fuelWeather": 46.91,
        "fuelFouling": 12.93,
        "fuelEngineEfficiency": 12.46,
        "fuelAuxiliaryEngineEfficiency": 2.35,
        "fuelTrim": 1.59
    },
    {
        "shipId": 4,
        "timestamp": "2024-01-08 00:00:00+00:00",
        "fuelWeather": 41.2,
        "fuelFouling": 15.69,
        "fuelEngineEfficiency": 18.26,
        "fuelAuxiliaryEngineEfficiency": 2.61,
        "fuelTrim": 1.93
    },
    {
        "shipId": 4,
        "timestamp": "2024-01-15 00:00:00+00:00",
        "fuelWeather": 31.58,
        "fuelFouling": 11.76,
        "fuelEngineEfficiency": 8.85,
        "fuelAuxiliaryEngineEfficiency": 1.5,
        "fuelTrim": 1.46
    },
    {
        "shipId": 4,
        "timestamp": "2024-01-22 00:00:00+00:00",
        "fuelWeather": 39.18,
        "fuelFouling": 15.61,
        "fuelEngineEfficiency": 10.45,
        "fuelAuxiliaryEngineEfficiency": 1.67,
        "fuelTrim": 1.43
    },
    {
        "shipId": 4,
        "timestamp": "2024-01-29 00:00:00+00:00",
        "fuelWeather": 48.82,
        "fuelFouling": 10.2,
        "fuelEngineEfficiency": 14.37,
        "fuelAuxiliaryEngineEfficiency": 2.85,
        "fuelTrim": 1.54
    },
    {
        "shipId": 4,
        "timestamp": "2024-02-05 00:00:00+00:00",
        "fuelWeather": 50.15,
        "fuelFouling": 17.66,
        "fuelEngineEfficiency": 11.84,
        "fuelAuxiliaryEngineEfficiency": 2.55,
        "fuelTrim": 1.87
    },
    {
        "shipId": 4,
        "timestamp": "2024-02-12 00:00:00+00:00",
        "fuelWeather": 29.93,
        "fuelFouling": 7.9,
        "fuelEngineEfficiency": 8.74,
        "fuelAuxiliaryEngineEfficiency": 1.93,
        "fuelTrim": 1.37
    },
    {
        "shipId": 4,
        "timestamp": "2024-02-19 00:00:00+00:00",
        "fuelWeather": 28.05,
        "fuelFouling": 8.21,
        "fuelEngineEfficiency": 12.06,
        "fuelAuxiliaryEngineEfficiency": 2.21,
        "fuelTrim": 1.41
    },
    {
        "shipId": 4,
        "timestamp": "2024-02-26 00:00:00+00:00",
        "fuelWeather": 41.1,
        "fuelFouling": 12.28,
        "fuelEngineEfficiency": 15.15,
        "fuelAuxiliaryEngineEfficiency": 2.74,
        "fuelTrim": 1.52
    },
    {
        "shipId": 4,
        "timestamp": "2024-03-04 00:00:00+00:00",
        "fuelWeather": 54.73,
        "fuelFouling": 16.42,
        "fuelEngineEfficiency": 17.2,
        "fuelAuxiliaryEngineEfficiency": 3.36,
        "fuelTrim": 2.01
    },
    {
        "shipId": 4,
        "timestamp": "2024-03-11 00:00:00+00:00",
        "fuelWeather": 51.52,
        "fuelFouling": 15.76,
        "fuelEngineEfficiency": 11.22,
        "fuelAuxiliaryEngineEfficiency": 2.4,
        "fuelTrim": 1.41
    },
    {
        "shipId": 4,
        "timestamp": "2024-03-18 00:00:00+00:00",
        "fuelWeather": 29.5,
        "fuelFouling": 12.83,
        "fuelEngineEfficiency": 8.58,
        "fuelAuxiliaryEngineEfficiency": 1.66,
        "fuelTrim": 1.49
    },
    {
        "shipId": 4,
        "timestamp": "2024-03-25 00:00:00+00:00",
        "fuelWeather": 53.61,
        "fuelFouling": 11.56,
        "fuelEngineEfficiency": 13.91,
        "fuelAuxiliaryEngineEfficiency": 2.45,
        "fuelTrim": 1.39
    },
    {
        "shipId": 4,
        "timestamp": "2024-04-01 00:00:00+00:00",
        "fuelWeather": 40.88,
        "fuelFouling": 11.54,
        "fuelEngineEfficiency": 10.52,
        "fuelAuxiliaryEngineEfficiency": 3.06,
        "fuelTrim": 1.68
    },
    {
        "shipId": 4,
        "timestamp": "2024-04-08 00:00:00+00:00",
        "fuelWeather": 60.67,
        "fuelFouling": 16.47,
        "fuelEngineEfficiency": 19,
        "fuelAuxiliaryEngineEfficiency": 2.61,
        "fuelTrim": 2.04
    },
    {
        "shipId": 4,
        "timestamp": "2024-04-15 00:00:00+00:00",
        "fuelWeather": 49.76,
        "fuelFouling": 11.46,
        "fuelEngineEfficiency": 9.96,
        "fuelAuxiliaryEngineEfficiency": 2.77,
        "fuelTrim": 1.5
    },
    {
        "shipId": 4,
        "timestamp": "2024-04-22 00:00:00+00:00",
        "fuelWeather": 40.97,
        "fuelFouling": 15.24,
        "fuelEngineEfficiency": 14.14,
        "fuelAuxiliaryEngineEfficiency": 2.65,
        "fuelTrim": 1.89
    },
    {
        "shipId": 4,
        "timestamp": "2024-04-29 00:00:00+00:00",
        "fuelWeather": 55.18,
        "fuelFouling": 12.35,
        "fuelEngineEfficiency": 12.98,
        "fuelAuxiliaryEngineEfficiency": 2.24,
        "fuelTrim": 1.4
    },
    {
        "shipId": 4,
        "timestamp": "2024-05-06 00:00:00+00:00",
        "fuelWeather": 31.94,
        "fuelFouling": 7.89,
        "fuelEngineEfficiency": 10.68,
        "fuelAuxiliaryEngineEfficiency": 1.88,
        "fuelTrim": 1.49
    },
    {
        "shipId": 4,
        "timestamp": "2024-05-13 00:00:00+00:00",
        "fuelWeather": 48.19,
        "fuelFouling": 9.96,
        "fuelEngineEfficiency": 11.02,
        "fuelAuxiliaryEngineEfficiency": 2.52,
        "fuelTrim": 1.32
    },
    {
        "shipId": 4,
        "timestamp": "2024-05-20 00:00:00+00:00",
        "fuelWeather": 49.07,
        "fuelFouling": 9.71,
        "fuelEngineEfficiency": 13.89,
        "fuelAuxiliaryEngineEfficiency": 2.89,
        "fuelTrim": 1.48
    },
    {
        "shipId": 4,
        "timestamp": "2024-05-27 00:00:00+00:00",
        "fuelWeather": 42.35,
        "fuelFouling": 10,
        "fuelEngineEfficiency": 10.86,
        "fuelAuxiliaryEngineEfficiency": 2.56,
        "fuelTrim": 1.65
    },
    {
        "shipId": 4,
        "timestamp": "2024-06-03 00:00:00+00:00",
        "fuelWeather": 50.54,
        "fuelFouling": 9.44,
        "fuelEngineEfficiency": 12.86,
        "fuelAuxiliaryEngineEfficiency": 2.91,
        "fuelTrim": 1.18
    },
    {
        "shipId": 4,
        "timestamp": "2024-06-10 00:00:00+00:00",
        "fuelWeather": 29.13,
        "fuelFouling": 12.39,
        "fuelEngineEfficiency": 12.67,
        "fuelAuxiliaryEngineEfficiency": 1.45,
        "fuelTrim": 1.3
    },
    {
        "shipId": 4,
        "timestamp": "2024-06-17 00:00:00+00:00",
        "fuelWeather": 49.98,
        "fuelFouling": 14.42,
        "fuelEngineEfficiency": 14.44,
        "fuelAuxiliaryEngineEfficiency": 3.19,
        "fuelTrim": 1.46
    },
    {
        "shipId": 5,
        "timestamp": "2024-01-01 00:00:00+00:00",
        "fuelWeather": 50.68,
        "fuelFouling": 17.41,
        "fuelEngineEfficiency": 18.95,
        "fuelAuxiliaryEngineEfficiency": 2.1,
        "fuelTrim": 1.74
    },
    {
        "shipId": 5,
        "timestamp": "2024-01-08 00:00:00+00:00",
        "fuelWeather": 44.08,
        "fuelFouling": 17.13,
        "fuelEngineEfficiency": 16.11,
        "fuelAuxiliaryEngineEfficiency": 3.05,
        "fuelTrim": 2.09
    },
    {
        "shipId": 5,
        "timestamp": "2024-01-15 00:00:00+00:00",
        "fuelWeather": 53.36,
        "fuelFouling": 14.06,
        "fuelEngineEfficiency": 17.37,
        "fuelAuxiliaryEngineEfficiency": 3.78,
        "fuelTrim": 1.75
    },
    {
        "shipId": 5,
        "timestamp": "2024-01-22 00:00:00+00:00",
        "fuelWeather": 64.19,
        "fuelFouling": 19.02,
        "fuelEngineEfficiency": 15.16,
        "fuelAuxiliaryEngineEfficiency": 3.57,
        "fuelTrim": 2.09
    },
    {
        "shipId": 5,
        "timestamp": "2024-01-29 00:00:00+00:00",
        "fuelWeather": 44.86,
        "fuelFouling": 10.9,
        "fuelEngineEfficiency": 14.24,
        "fuelAuxiliaryEngineEfficiency": 2.11,
        "fuelTrim": 1.29
    },
    {
        "shipId": 5,
        "timestamp": "2024-02-05 00:00:00+00:00",
        "fuelWeather": 36.95,
        "fuelFouling": 15.75,
        "fuelEngineEfficiency": 14.79,
        "fuelAuxiliaryEngineEfficiency": 1.61,
        "fuelTrim": 1.47
    },
    {
        "shipId": 5,
        "timestamp": "2024-02-12 00:00:00+00:00",
        "fuelWeather": 60.81,
        "fuelFouling": 14.95,
        "fuelEngineEfficiency": 11.18,
        "fuelAuxiliaryEngineEfficiency": 2.31,
        "fuelTrim": 2.19
    },
    {
        "shipId": 5,
        "timestamp": "2024-02-19 00:00:00+00:00",
        "fuelWeather": 47.95,
        "fuelFouling": 10.46,
        "fuelEngineEfficiency": 14.52,
        "fuelAuxiliaryEngineEfficiency": 1.51,
        "fuelTrim": 1.96
    },
    {
        "shipId": 5,
        "timestamp": "2024-02-26 00:00:00+00:00",
        "fuelWeather": 73.18,
        "fuelFouling": 16.53,
        "fuelEngineEfficiency": 17.92,
        "fuelAuxiliaryEngineEfficiency": 2.39,
        "fuelTrim": 2.3
    },
    {
        "shipId": 5,
        "timestamp": "2024-03-04 00:00:00+00:00",
        "fuelWeather": 39.71,
        "fuelFouling": 8.84,
        "fuelEngineEfficiency": 12.7,
        "fuelAuxiliaryEngineEfficiency": 2.43,
        "fuelTrim": 1.26
    },
    {
        "shipId": 5,
        "timestamp": "2024-03-11 00:00:00+00:00",
        "fuelWeather": 44.22,
        "fuelFouling": 16.44,
        "fuelEngineEfficiency": 13.84,
        "fuelAuxiliaryEngineEfficiency": 3.23,
        "fuelTrim": 1.77
    },
    {
        "shipId": 5,
        "timestamp": "2024-03-18 00:00:00+00:00",
        "fuelWeather": 53.74,
        "fuelFouling": 11.99,
        "fuelEngineEfficiency": 11.84,
        "fuelAuxiliaryEngineEfficiency": 1.71,
        "fuelTrim": 1.74
    },
    {
        "shipId": 5,
        "timestamp": "2024-03-25 00:00:00+00:00",
        "fuelWeather": 48.73,
        "fuelFouling": 16.49,
        "fuelEngineEfficiency": 13.14,
        "fuelAuxiliaryEngineEfficiency": 2.47,
        "fuelTrim": 1.47
    },
    {
        "shipId": 5,
        "timestamp": "2024-04-01 00:00:00+00:00",
        "fuelWeather": 64.06,
        "fuelFouling": 18.9,
        "fuelEngineEfficiency": 14.58,
        "fuelAuxiliaryEngineEfficiency": 3.47,
        "fuelTrim": 1.89
    },
    {
        "shipId": 5,
        "timestamp": "2024-04-08 00:00:00+00:00",
        "fuelWeather": 45.07,
        "fuelFouling": 10.59,
        "fuelEngineEfficiency": 9.26,
        "fuelAuxiliaryEngineEfficiency": 1.21,
        "fuelTrim": 1.72
    },
    {
        "shipId": 5,
        "timestamp": "2024-04-15 00:00:00+00:00",
        "fuelWeather": 40.61,
        "fuelFouling": 11.49,
        "fuelEngineEfficiency": 13.79,
        "fuelAuxiliaryEngineEfficiency": 1.59,
        "fuelTrim": 1.4
    },
    {
        "shipId": 5,
        "timestamp": "2024-04-22 00:00:00+00:00",
        "fuelWeather": 37.36,
        "fuelFouling": 8.1,
        "fuelEngineEfficiency": 12.73,
        "fuelAuxiliaryEngineEfficiency": 2.46,
        "fuelTrim": 1.39
    },
    {
        "shipId": 5,
        "timestamp": "2024-04-29 00:00:00+00:00",
        "fuelWeather": 68.55,
        "fuelFouling": 15.44,
        "fuelEngineEfficiency": 16.57,
        "fuelAuxiliaryEngineEfficiency": 3.59,
        "fuelTrim": 2.1
    },
    {
        "shipId": 5,
        "timestamp": "2024-05-06 00:00:00+00:00",
        "fuelWeather": 34.85,
        "fuelFouling": 15.15,
        "fuelEngineEfficiency": 14.74,
        "fuelAuxiliaryEngineEfficiency": 2.64,
        "fuelTrim": 1.69
    },
    {
        "shipId": 5,
        "timestamp": "2024-05-13 00:00:00+00:00",
        "fuelWeather": 38.06,
        "fuelFouling": 12.75,
        "fuelEngineEfficiency": 10.68,
        "fuelAuxiliaryEngineEfficiency": 1.37,
        "fuelTrim": 1.27
    },
    {
        "shipId": 5,
        "timestamp": "2024-05-20 00:00:00+00:00",
        "fuelWeather": 41.54,
        "fuelFouling": 11.07,
        "fuelEngineEfficiency": 15.57,
        "fuelAuxiliaryEngineEfficiency": 2.67,
        "fuelTrim": 1.9
    },
    {
        "shipId": 5,
        "timestamp": "2024-05-27 00:00:00+00:00",
        "fuelWeather": 51.07,
        "fuelFouling": 14.69,
        "fuelEngineEfficiency": 16.57,
        "fuelAuxiliaryEngineEfficiency": 3.14,
        "fuelTrim": 1.73
    },
    {
        "shipId": 5,
        "timestamp": "2024-06-03 00:00:00+00:00",
        "fuelWeather": 43.2,
        "fuelFouling": 17.94,
        "fuelEngineEfficiency": 15.6,
        "fuelAuxiliaryEngineEfficiency": 4.05,
        "fuelTrim": 1.91
    },
    {
        "shipId": 5,
        "timestamp": "2024-06-10 00:00:00+00:00",
        "fuelWeather": 58.27,
        "fuelFouling": 17.47,
        "fuelEngineEfficiency": 13.8,
        "fuelAuxiliaryEngineEfficiency": 2.67,
        "fuelTrim": 1.75
    },
    {
        "shipId": 5,
        "timestamp": "2024-06-17 00:00:00+00:00",
        "fuelWeather": 43.6,
        "fuelFouling": 13.94,
        "fuelEngineEfficiency": 10.59,
        "fuelAuxiliaryEngineEfficiency": 2.34,
        "fuelTrim": 1.68
    },
    {
        "shipId": 6,
        "timestamp": "2024-01-01 00:00:00+00:00",
        "fuelWeather": 70.66,
        "fuelFouling": 17.94,
        "fuelEngineEfficiency": 14.69,
        "fuelAuxiliaryEngineEfficiency": 2.92,
        "fuelTrim": 1.97
    },
    {
        "shipId": 6,
        "timestamp": "2024-01-08 00:00:00+00:00",
        "fuelWeather": 59.47,
        "fuelFouling": 14.83,
        "fuelEngineEfficiency": 13.13,
        "fuelAuxiliaryEngineEfficiency": 2.96,
        "fuelTrim": 1.96
    },
    {
        "shipId": 6,
        "timestamp": "2024-01-15 00:00:00+00:00",
        "fuelWeather": 42.79,
        "fuelFouling": 11.7,
        "fuelEngineEfficiency": 12.77,
        "fuelAuxiliaryEngineEfficiency": 2.16,
        "fuelTrim": 1.64
    },
    {
        "shipId": 6,
        "timestamp": "2024-01-22 00:00:00+00:00",
        "fuelWeather": 52.32,
        "fuelFouling": 13.6,
        "fuelEngineEfficiency": 16.7,
        "fuelAuxiliaryEngineEfficiency": 2.94,
        "fuelTrim": 2.02
    },
    {
        "shipId": 6,
        "timestamp": "2024-01-29 00:00:00+00:00",
        "fuelWeather": 40.18,
        "fuelFouling": 11.55,
        "fuelEngineEfficiency": 10.24,
        "fuelAuxiliaryEngineEfficiency": 1.9,
        "fuelTrim": 1.81
    },
    {
        "shipId": 6,
        "timestamp": "2024-02-05 00:00:00+00:00",
        "fuelWeather": 49.42,
        "fuelFouling": 10.92,
        "fuelEngineEfficiency": 10.24,
        "fuelAuxiliaryEngineEfficiency": 1.9,
        "fuelTrim": 1.29
    },
    {
        "shipId": 6,
        "timestamp": "2024-02-12 00:00:00+00:00",
        "fuelWeather": 66.48,
        "fuelFouling": 17,
        "fuelEngineEfficiency": 17.73,
        "fuelAuxiliaryEngineEfficiency": 2.49,
        "fuelTrim": 2.11
    },
    {
        "shipId": 6,
        "timestamp": "2024-02-19 00:00:00+00:00",
        "fuelWeather": 30.35,
        "fuelFouling": 11.39,
        "fuelEngineEfficiency": 11.87,
        "fuelAuxiliaryEngineEfficiency": 2.41,
        "fuelTrim": 1.47
    },
    {
        "shipId": 6,
        "timestamp": "2024-02-26 00:00:00+00:00",
        "fuelWeather": 41.71,
        "fuelFouling": 12.15,
        "fuelEngineEfficiency": 9.9,
        "fuelAuxiliaryEngineEfficiency": 2.46,
        "fuelTrim": 1.82
    },
    {
        "shipId": 6,
        "timestamp": "2024-03-04 00:00:00+00:00",
        "fuelWeather": 47.7,
        "fuelFouling": 12.8,
        "fuelEngineEfficiency": 16.6,
        "fuelAuxiliaryEngineEfficiency": 3.29,
        "fuelTrim": 2.07
    },
    {
        "shipId": 6,
        "timestamp": "2024-03-11 00:00:00+00:00",
        "fuelWeather": 47.6,
        "fuelFouling": 17.82,
        "fuelEngineEfficiency": 18.38,
        "fuelAuxiliaryEngineEfficiency": 3.6,
        "fuelTrim": 1.89
    },
    {
        "shipId": 6,
        "timestamp": "2024-03-18 00:00:00+00:00",
        "fuelWeather": 61.64,
        "fuelFouling": 14.41,
        "fuelEngineEfficiency": 13.91,
        "fuelAuxiliaryEngineEfficiency": 2,
        "fuelTrim": 1.58
    },
    {
        "shipId": 6,
        "timestamp": "2024-03-25 00:00:00+00:00",
        "fuelWeather": 53.22,
        "fuelFouling": 13.35,
        "fuelEngineEfficiency": 18.5,
        "fuelAuxiliaryEngineEfficiency": 3.27,
        "fuelTrim": 1.54
    },
    {
        "shipId": 6,
        "timestamp": "2024-04-01 00:00:00+00:00",
        "fuelWeather": 26.5,
        "fuelFouling": 9.79,
        "fuelEngineEfficiency": 8.47,
        "fuelAuxiliaryEngineEfficiency": 1.38,
        "fuelTrim": 1.51
    },
    {
        "shipId": 6,
        "timestamp": "2024-04-08 00:00:00+00:00",
        "fuelWeather": 41.44,
        "fuelFouling": 8.57,
        "fuelEngineEfficiency": 13.65,
        "fuelAuxiliaryEngineEfficiency": 1.69,
        "fuelTrim": 1.31
    },
    {
        "shipId": 6,
        "timestamp": "2024-04-15 00:00:00+00:00",
        "fuelWeather": 44.56,
        "fuelFouling": 10.88,
        "fuelEngineEfficiency": 8.61,
        "fuelAuxiliaryEngineEfficiency": 1.5,
        "fuelTrim": 1.42
    },
    {
        "shipId": 6,
        "timestamp": "2024-04-22 00:00:00+00:00",
        "fuelWeather": 52.46,
        "fuelFouling": 12.86,
        "fuelEngineEfficiency": 16.11,
        "fuelAuxiliaryEngineEfficiency": 1.55,
        "fuelTrim": 1.54
    },
    {
        "shipId": 6,
        "timestamp": "2024-04-29 00:00:00+00:00",
        "fuelWeather": 50.34,
        "fuelFouling": 17,
        "fuelEngineEfficiency": 20.3,
        "fuelAuxiliaryEngineEfficiency": 1.98,
        "fuelTrim": 1.73
    },
    {
        "shipId": 6,
        "timestamp": "2024-05-06 00:00:00+00:00",
        "fuelWeather": 36,
        "fuelFouling": 8.51,
        "fuelEngineEfficiency": 11.83,
        "fuelAuxiliaryEngineEfficiency": 1.31,
        "fuelTrim": 1.29
    },
    {
        "shipId": 6,
        "timestamp": "2024-05-13 00:00:00+00:00",
        "fuelWeather": 57.92,
        "fuelFouling": 18.37,
        "fuelEngineEfficiency": 18.13,
        "fuelAuxiliaryEngineEfficiency": 3.09,
        "fuelTrim": 2.45
    },
    {
        "shipId": 6,
        "timestamp": "2024-05-20 00:00:00+00:00",
        "fuelWeather": 32.26,
        "fuelFouling": 9.59,
        "fuelEngineEfficiency": 8.63,
        "fuelAuxiliaryEngineEfficiency": 1.81,
        "fuelTrim": 1.33
    },
    {
        "shipId": 6,
        "timestamp": "2024-05-27 00:00:00+00:00",
        "fuelWeather": 40.62,
        "fuelFouling": 11.37,
        "fuelEngineEfficiency": 14.83,
        "fuelAuxiliaryEngineEfficiency": 3.01,
        "fuelTrim": 1.56
    },
    {
        "shipId": 6,
        "timestamp": "2024-06-03 00:00:00+00:00",
        "fuelWeather": 60.82,
        "fuelFouling": 12.59,
        "fuelEngineEfficiency": 18.5,
        "fuelAuxiliaryEngineEfficiency": 1.72,
        "fuelTrim": 2.21
    },
    {
        "shipId": 6,
        "timestamp": "2024-06-10 00:00:00+00:00",
        "fuelWeather": 50.59,
        "fuelFouling": 11.65,
        "fuelEngineEfficiency": 10.3,
        "fuelAuxiliaryEngineEfficiency": 2.07,
        "fuelTrim": 1.49
    },
    {
        "shipId": 6,
        "timestamp": "2024-06-17 00:00:00+00:00",
        "fuelWeather": 33.58,
        "fuelFouling": 12.09,
        "fuelEngineEfficiency": 13.76,
        "fuelAuxiliaryEngineEfficiency": 1.91,
        "fuelTrim": 1.79
    }
]

export default ExcessFuel;