import React from "react";
import orange from "@mui/material/colors/orange";
import { useAuthMethod, useAuthUser } from "../../../../utility/AuthHooks";
import { alpha, Box, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fonts } from "../../../../../shared/constants/AppEnums";
import { useNavigate } from "react-router-dom";

interface UserInfoProps {
  color?: string;
}

const UserInfo: React.FC<UserInfoProps> = ({ color = "text.secondary" }) => {
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          py: 3,
          px: 3,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        className="user-info-view"
      >
        <IconButton
          className="icon-btn"
          sx={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            color: (theme) => theme.palette.text.secondary,

            borderColor: "transparent",
          }}
          size="large"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="51"
            viewBox="0 0 49 51"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.5 5.84375C19.7077 5.84375 15.8229 9.88718 15.8229 14.875C15.8229 19.8628 19.7077 23.9062 24.5 23.9062C29.2922 23.9062 33.177 19.8628 33.177 14.875C33.177 9.88718 29.2922 5.84375 24.5 5.84375ZM12.7604 14.875C12.7604 8.12677 18.0164 2.65625 24.5 2.65625C30.9836 2.65625 36.2395 8.12677 36.2395 14.875C36.2395 21.6232 30.9836 27.0938 24.5 27.0938C18.0164 27.0938 12.7604 21.6232 12.7604 14.875Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.43079 46.75C5.43079 37.3557 14.2857 30.2812 24.4999 30.2812C34.7141 30.2812 43.5691 37.3557 43.5691 46.75C43.5691 47.6302 42.8835 48.3437 42.0379 48.3437C41.1922 48.3437 40.5066 47.6302 40.5066 46.75C40.5066 39.6968 33.6407 33.4687 24.4999 33.4687C15.3591 33.4687 8.49329 39.6968 8.49329 46.75C8.49329 47.6302 7.80772 48.3437 6.96204 48.3437C6.11635 48.3437 5.43079 47.6302 5.43079 46.75Z"
              fill="white"
            />
          </svg>
        </IconButton>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/my-account");
          }}
        >
          My account
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default UserInfo;
