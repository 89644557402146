import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { SensorReading } from 'types/models/Sensor';

// Create an entity adapter
const sensorReadingsAdapter = createEntityAdapter({
    selectId: (ship: SensorReading) => ship.sensorId,
});

const initialState = sensorReadingsAdapter.getInitialState();

const sensorReadingsSlice = createSlice({
    name: 'sensorReadings',
    initialState,
    reducers: {
        setSensorReadings: (state, action: PayloadAction<SensorReading[]>) => {
            sensorReadingsAdapter.setAll(state, action.payload);
        },
    },
});

// Can create a set of memoized selectors based on the location of this entity state
export const sensorReadingsSelectors = sensorReadingsAdapter.getSelectors<RootState>(
    (state) => state.sensorReadings
)
export const { setSensorReadings } = sensorReadingsSlice.actions;

export default sensorReadingsSlice.reducer;