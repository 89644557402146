import {
  mapShipDataByMetric,
  mapShipDataToCourseMap,
} from "maps/dashboardData";
import {
  DashboardActionTypes,
  GET_SHIP_GENERAL_DATA,
  GET_SHIP_SYSTEM_DATA,
  GET_SHIP_PERFORMANCE_DATA,
  GET_SHIP_SUB_SYSTEM_DATA,
} from "types/actions/Dashboard.actions";
import { ShipFeatureReading } from "types/models/Ship";

const initialState: {
  rpm: ShipFeatureReading[] | null;
  velocity: ShipFeatureReading[] | null;
  elLoad: ShipFeatureReading[] | null;
  fuel: ShipFeatureReading[] | null;
  slip: ShipFeatureReading[] | null;
  cii: ShipFeatureReading[] | null;
  course: any | null;
  shipSystemData: any;
  sensorsData: any;
} = {
  rpm: null,
  velocity: null,
  elLoad: null,
  fuel: null,
  slip: null,
  cii: null,
  course: null,
  shipSystemData: null,
  sensorsData: null,
};

const Dashboard = (state = initialState, action: DashboardActionTypes) => {
  switch (action.type) {
    case GET_SHIP_GENERAL_DATA:
      return {
        ...state,
        rpm: mapShipDataByMetric(action.payload, "rpm"),
        velocity: mapShipDataByMetric(action.payload, "velocity"),
        course: mapShipDataToCourseMap(action.payload),
      };
   
    case GET_SHIP_PERFORMANCE_DATA:
      return {
        ...state,
        elLoad: mapShipDataByMetric(action.payload, "el_load"),
        slip: mapShipDataByMetric(action.payload, "slip"),
        fuel: mapShipDataByMetric(action.payload, "fuel_cons"),
        cii: mapShipDataByMetric(action.payload, "cii"),
      };
    default:
      return state;
  }
};
export default Dashboard;
