import React, { useState } from "react";
import { IconButton, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppNotificationContent from "./AppNotificationContent";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AppTooltip from "../AppTooltip";
import { alpha } from "@mui/material/styles";
import { SxProps } from "@mui/system";

interface AppNotificationsProps {
  drawerPosition?: "left" | "top" | "right" | "bottom";
  tooltipPosition?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  isMenu?: boolean;
  sxNotificationContentStyle?: SxProps<Theme>;
}

const AppNotifications: React.FC<AppNotificationsProps> = ({
  drawerPosition = "right",
  tooltipPosition = "bottom",
  isMenu = false,
  sxNotificationContentStyle = {},
}) => {
  const [showNotification, setShowNotification] = useState(false);

  return (
    <>
      {isMenu ? (
        <Box component="span" onClick={() => setShowNotification(true)}>
          Message
        </Box>
      ) : (
        <AppTooltip title="Notification" placement={tooltipPosition}>
          <IconButton
            className="icon-btn"
            sx={{
              borderRadius: "50%",
              width: 40,
              height: 40,
              color: (theme) => theme.palette.text.secondary,
            }}
            onClick={() => setShowNotification(true)}
            size="large"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <path
                d="M24.5408 41.9154C19.7838 41.9154 15.0267 41.16 10.5146 39.6492C8.79958 39.0571 7.49291 37.8525 6.92125 36.2804C6.32916 34.7083 6.53333 32.9729 7.4725 31.4213L9.82041 27.5217C10.3104 26.705 10.7596 25.0717 10.7596 24.1121V18.2117C10.7596 10.6167 16.9458 4.43042 24.5408 4.43042C32.1358 4.43042 38.3221 10.6167 38.3221 18.2117V24.1121C38.3221 25.0513 38.7712 26.705 39.2612 27.5421L41.5888 31.4213C42.4667 32.8913 42.63 34.6675 42.0379 36.2804C41.4458 37.8933 40.1596 39.1183 38.5467 39.6492C34.055 41.16 29.2979 41.9154 24.5408 41.9154ZM24.5408 7.49292C18.6404 7.49292 13.8221 12.2908 13.8221 18.2117V24.1121C13.8221 25.6025 13.2096 27.8075 12.4542 29.0938L10.1062 32.9933C9.65708 33.7488 9.53458 34.545 9.8 35.2188C10.045 35.9129 10.6575 36.4438 11.4946 36.7296C20.0287 39.5879 29.0733 39.5879 37.6075 36.7296C38.3425 36.4846 38.9142 35.9333 39.1796 35.1983C39.445 34.4633 39.3838 33.6671 38.9754 32.9933L36.6275 29.0938C35.8517 27.7667 35.2596 25.5821 35.2596 24.0917V18.2117C35.2596 12.2908 30.4617 7.49292 24.5408 7.49292Z"
                fill="white"
              />
              <path
                d="M28.3383 8.04418C28.1954 8.04418 28.0525 8.02376 27.9096 7.98293C27.3175 7.8196 26.7458 7.6971 26.1946 7.61543C24.4591 7.39085 22.785 7.51335 21.2129 7.98293C20.6412 8.16668 20.0287 7.98293 19.6408 7.55418C19.2529 7.12543 19.1304 6.51293 19.355 5.96168C20.1921 3.81793 22.2337 2.40918 24.5612 2.40918C26.8887 2.40918 28.9304 3.79751 29.7675 5.96168C29.9716 6.51293 29.8696 7.12543 29.4816 7.55418C29.1754 7.88085 28.7466 8.04418 28.3383 8.04418Z"
                fill="white"
              />
              <path
                d="M24.5408 46.5704C22.5196 46.5704 20.5596 45.7538 19.1304 44.3246C17.7012 42.8954 16.8846 40.9354 16.8846 38.9142H19.9471C19.9471 40.1188 20.4371 41.3029 21.2946 42.1604C22.1521 43.0179 23.3362 43.5079 24.5408 43.5079C27.0725 43.5079 29.1346 41.4459 29.1346 38.9142H32.1971C32.1971 43.1404 28.7671 46.5704 24.5408 46.5704Z"
                fill="white"
              />
            </svg>
          </IconButton>
        </AppTooltip>
      )}

      <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowNotification(false)}
        />
      </Drawer>
    </>
  );
};

export default AppNotifications;
